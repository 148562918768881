import React from "react";
import PictureWText from "./PictureWText";
import { DiscordBlock } from "./DiscordBlock";
import { SignupBlock } from "./SignupBlock";
import { LinksBlock } from "../footer/LinksBlock";
import { NewFooter } from "../footer/NewFooter";
import { NewHeader } from "../header/NewHeader";
import { IntroBlock } from "./IntroBlock";
import { LoginBlock } from "./LoginBlock";
import { NewWeeklyUpdatesBlock } from "./WeeklyUpdatesBlock";
import { FoundersPackBlock } from "./FoundersPackBlock";
import { showFounders } from "../../theme";

function HomeIndex() {
	return (
		<>
			{/* header-area */}
			<NewHeader></NewHeader>
			{/* main-area */}
			<main>
				<LoginBlock></LoginBlock>
				<IntroBlock></IntroBlock>
				{showFounders ? <FoundersPackBlock></FoundersPackBlock> : null}

				<PictureWText
					title="CREATE UNBELIEVABLE STRUCTURES"
					text="Copy and paste building pieces, Create from any resource you collect."
					backgroundIndex={2}
					left={true}
				/>
				<PictureWText
					title="FIGHT TO SURVIVE"
					text="Use an array of weapons and spells to fight world bosses and mobs. "
					backgroundIndex={3}
					left={true}
				/>
				<PictureWText
					title="EXPLORE AN INFINITE WORLD"
					text="Glide, climb, swim, ride, fly or run around an infinite procedurally generated world."
					backgroundIndex={1}
					left={false}
				/>
				<PictureWText
					title="ALL PLAYERS, ONE WORLD"
					text="With everyone in one server, you can play with your friends, and make new ones."
					backgroundIndex={4}
					left={false}
				/>
				<PictureWText
					title="TEXT-TO-3D OBJECT CREATION"
					text="Bring any idea to life by just typing it into our DreamForge."
					backgroundIndex={5}
					left={true}
				/>

				<DiscordBlock></DiscordBlock>
				<NewWeeklyUpdatesBlock
					bShowFull={false}
				></NewWeeklyUpdatesBlock>
				<SignupBlock></SignupBlock>
				<LinksBlock></LinksBlock>
			</main>
			{/* main-area-end */}
			<NewFooter />
		</>
	);
}

export default HomeIndex;
