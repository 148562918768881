import React from "react";
import { StylishArrowDown } from "../widget/StylishArrowDown";
import { GameplayTrailerBlock } from "./GameplayTrailerBlock";
import useId from "@mui/material/utils/useId";

export function IntroBlock() {
	const id = useId();

	const onButtonPress = () => {
		const nextItem = document.getElementById(id).nextSibling;
		var rect = nextItem.getBoundingClientRect();

		var scrollToPos = window.scrollY + rect.top;
		console.log(scrollToPos);
		window.scrollTo({ top: scrollToPos, behavior: "smooth" });
	};

	return (
		<div id={id} className="intro-frame">
			<GameplayTrailerBlock></GameplayTrailerBlock>
			<div className="meet-the-team-text">
				<div className="section-header">
					<img
						className="header-style-graphic-right"
						src="assets/img/new_design/GraphicAssets/Header-style-graphic-left.png"
					></img>

					<div className="SectionHeaderTitle">
						DreamWorld The Infinite Sandbox MMO
					</div>
					<img
						className="header-style-graphic-left"
						src="assets/img/new_design/GraphicAssets/Header-style-graphic-right.png"
					></img>
				</div>
				<p className="welcome-text">
					Welcome to DreamWorld, the groundbreaking Sandbox MMO where
					all players create together in a single infinite world.
					Build incredible structures with powerful tools. Adventure
					through our biomes, collect rare resources, craft the best
					gear, and take down epic world bosses with friends. Farm,
					craft, and socialize. Explore the world with all players on
					one server. How will you shape DreamWorld?
				</p>
			</div>
			<StylishArrowDown onButtonPress={onButtonPress} />
		</div>
	);
}
