import React from "react";
import { StylizedTextBlock } from "../../components/contact-us/StylizedTextBlock";
import { Link } from "react-router-dom";

export const ButtonMailto = ({ mailto, label }) => {
	return (
		<Link
			className="text"
			to="#"
			onClick={(e) => {
				window.location.href = mailto;
				e.preventDefault();
			}}
		>
			<u>{label}</u>
		</Link>
	);
};

export function AdditionalContacts() {
	const text = (
		<>
			<div className="additional-emails">
				<p className="text-group">
					<div className="title">Game Support</div>
					<ButtonMailto
						label="support@playdreamworld.com"
						mailto="mailto:support@playdreamworld.com"
					/>
				</p>
				<p className="text-group">
					<div className="title">PRESS/CONTENT CREATORS</div>
					<ButtonMailto
						label="marketing@playdreamworld.com"
						mailto="mailto:marketing@playdreamworld.com"
					/>
				</p>
				<p className="text-group">
					<div className="title">Press</div>
					<ButtonMailto
						label="marketing@playdreamworld.com"
						mailto="mailto:marketing@playdreamworld.com"
					/>
				</p>
			</div>
		</>
	);

	return (
		<>
			<StylizedTextBlock
				title="Additional Contacts"
				text={text}
			></StylizedTextBlock>
		</>
	);
}
